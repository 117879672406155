import { ClipLoader } from 'react-spinners'
import { formatNumber } from '../../../core/utils/common'
import Text from '../../../core/utils/Text'
import AnalysisDateSelect from './AnalysisDateSelect'
import { getMainTheme } from '../../../core/utils/theme'

const OrderOtcWages = ({
	date,
	setDate,
	dates,
	customDates,
	setCustomDates,
	wagesLoading,
	wages,
	flow,
}) => {
	return (
		<div>
			<AnalysisDateSelect
				value={date}
				onChange={setDate}
				options={dates}
				customDates={customDates}
				onCustomChange={setCustomDates}
			/>
			<div
				className={`
            rounded-md bg-gray-100 dark:bg-mainBg py-3 px-5 shadow-md w-full
            border-[1px] border-slate-500 border-opacity-30 relative min-h-[140px]
                `}
			>
				{wagesLoading ? (
					<div className={'absolute left-0 top-0 w-full h-full flex items-center justify-center'}>
						<ClipLoader color={getMainTheme().active} size={20} />
					</div>
				) : null}

				<div className={'overflow-x-auto pb-2 h-full w-full flex items-center gap-5'}>
					{wages?.map((wage) => {
						return <Item wage={wage} flow={flow} key={wage.currency} />
					})}
				</div>
			</div>
		</div>
	)
}

const Item = ({ wage, flow, ...rest }) => {
	return (
		<div
			{...rest}
			className={`rounded-lg bg-white dark:bg-cardBg
                    h-full min-w-[300px] shadow flex items-center justify-center
                    dark:text-gray-300 px-5 py-3 text-sm
                `}
		>
			<div className={'flex w-full flex-col items-center gap-2'}>
				<div className={'flex items-center gap-1 w-full justify-between'}>
					<div className={'flex items-center gap-2'}>
						<div className={'dark:bg-gray-300 bg-gray-700 rounded w-[12px] h-[12px]'} />
						<span className={'mt-1'}>{wage.currency.toUpperCase()}</span>
					</div>
					<div className={'px-6 rounded dark:bg-gray-200 bg-slate-700'}>
						<Text className={'text-xs dark:text-slate-700 text-gray-200'} tid={flow} />
					</div>
				</div>
				<div className={'w-full flex items-center justify-between mt-3'}>
					<Text tid={'traded-volume'} className={'text-gray-500 text-xs'} />
					<span>{formatNumber(wage.volumes, { type: wage.currency })}</span>
				</div>
				<div className={'w-full flex items-center justify-between'}>
					<Text tid={'traded-wage'} className={'text-gray-500 text-xs'} />
					<span>{formatNumber(wage.wages, { type: wage.currency })}</span>
				</div>
			</div>
		</div>
	)
}

export default OrderOtcWages
