import { Link, useNavigate } from 'react-router-dom'
import { formatDate, formatNumber, getNames } from '../../../../core/utils/common.js'
import Text from '../../../../core/utils/Text.js'
import { Row } from '../../../../styles/CommonStyles.js'
import RTableLayout from '../../../responsive/layout/RTableLayout.jsx'
import { FaChevronLeft } from 'react-icons/fa'
import CoinRow from '../../../common/common/CoinRow.jsx'
import Operation from '../../../common/utils/Operation.jsx'
import { useMainContext } from '../../../../core/contexts/main.js'

const RStakingReportsList = ({ data }) => {
  const { lang } = useMainContext()
  const navigate = useNavigate()
  const onDetailsClicked = (item) => {
    navigate(`/reports/staking/${item._id}`)
  }

  return (
    <RTableLayout data={data}>
      {data?.data?.data?.map((item, idx) => {
        const { FULLNAME } = getNames(item.user.firstName, item.user.lastName)
        return (
          <Row index={idx} cs={'100%'}>
            <div className={'grid grid-c gap-2'}>
              <CoinRow coin={{ id: item.currency, name: item.currency, fa: item.currency }} />
              <Operation onDetailsClicked={() => onDetailsClicked(item)} hasDetails />

              <Text tid={'username'} />
              <Link to={`/users/${item.userId}`}>
                <span className={'underline'}>{FULLNAME}</span>
              </Link>

              <Text tid={'amount'} />

              <span>{formatNumber(item.amount, { type: item.currency })}</span>

              <Text tid={'date'} />

              <span>
                {`${formatDate(item.createdAt, 'date', lang)}-${formatDate(item.createdAt, 'time', lang)}`}
              </span>
              <Link to={`/reports/staking/${item._id}`} className={'col-span-2'}>
                <div
                  className={`
                                dark:bg-slate-800 bg-slate-200 rounded py-2 px-3
                                flex items-center gap-2 cursor-pointer
                                `}
                >
                  <span className={'mt-1 max-w-[95%] truncate'}>{item.planId}</span>
                </div>
              </Link>
            </div>
          </Row>
        )
      })}
    </RTableLayout>
  )
}

export default RStakingReportsList
