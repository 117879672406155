import { TABLET_SIZE } from '../../../../core/constants/common'
import { useWindowSize } from '../../../../core/hooks/common/useWindowSize'
import Text from '../../../../core/utils/Text'
import { Background } from '../../../../styles/CommonStyles'
import ModalLayout from '../../../layout/main/ModalLayout'
import Input from '../../../common/input/Input'
import { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import { useEditUserStake } from '../../../../core/services/react-query/staking'
import { formatNumber, stringToNumber } from '../../../../core/utils/common'

const EditUserStakeModal = ({ userStake, modal, onClose }) => {
	const { width, height } = useWindowSize()

	const [input, setInput] = useState({
		interestRate: '',
		interestMax: '',
		interestMin: '',
		affiliateInterestMax: '',
		affiliateInterestMin: '',
		affiliateInterestRate: '',
	})

	useEffect(() => {
		let hasCustom = 0
		try {
			hasCustom = Object.keys(userStake?.custom)?.length
		} catch (err) {}
		if (modal.data || hasCustom) {
			const s = hasCustom ? userStake?.custom : modal.data
			setInput({
				interestMax: formatNumber(s.interestMax),
				interestMin: formatNumber(s.interestMin),
				interestRate: formatNumber(s.interestRate),
				affiliateInterestMax: formatNumber(s.affiliateInterestMax),
				affiliateInterestMin: formatNumber(s.affiliateInterestMin),
				affiliateInterestRate: formatNumber(s.affiliateInterestRate),
			})
		}
	}, [modal, userStake])

	const onInputChange = (v, t) => {
		setInput((s) => ({ ...s, [t]: v }))
	}

	const { mutate: editPlan, isLoading: eidtPlanLoading } = useEditUserStake(onClose)
	const onEditPlan = () => {
		try {
			const newInput = {}
			for (const k of Object.keys(input)) {
				newInput[k] = stringToNumber(input[k])
			}
			editPlan({ payload: newInput, id: userStake._id })
		} catch (err) {}
	}

	return (
		<ModalLayout open={modal.show} width={'500px'} padding={'0'} isStatic={width > TABLET_SIZE}>
			<div className={`relative dark:text-gray-300 text-sm`}>
				<div
					className={`overflow-y-auto overflow-x-hidden`}
					style={{ maxHeight: `${(height * 80) / 100}px` }}
				>
					<div className={'px-3 py-3 w-full rounded-t-md dark:bg-cardBg'}>
						<Text tid={`update-plan`} />
					</div>

					<div
						className={'px-3 py-3 w-full text-xs flex items-center justify-between dark:bg-cardBg'}
					>
						<Text tid={'interest-info'} />
					</div>
					<div className={'grid grid-cols-1 lg:grid-cols-2 gap-x-3 p-3'}>
						<Background bg={'mainBg'} className={'mt-[-4px]'}>
							<Input
								value={input.interestRate}
								onInputChange={(v) => onInputChange(v, 'interestRate')}
								size={'small'}
								height={'40px'}
								label={'interest-rate-pc'}
								code
							/>
						</Background>

						<Background bg={'mainBg'} className={'mt-[-4px]'}>
							<Input
								value={input.affiliateInterestRate}
								onInputChange={(v) => onInputChange(v, 'affiliateInterestRate')}
								size={'small'}
								height={'40px'}
								label={'aff-interest-rate-pc'}
								code
							/>
						</Background>

						<Background bg={'mainBg'} className={'mt-[-4px]'}>
							<Input
								value={input.interestMin}
								onInputChange={(v) => onInputChange(v, 'interestMin')}
								size={'small'}
								height={'40px'}
								label={'interest-min'}
								currency={input.currency?.id}
								number
							/>
						</Background>

						<Background bg={'mainBg'} className={'mt-[-4px]'}>
							<Input
								value={input.interestMax}
								onInputChange={(v) => onInputChange(v, 'interestMax')}
								size={'small'}
								height={'40px'}
								label={'interest-max'}
								currency={input.currency?.id}
								number
							/>
						</Background>

						<Background bg={'mainBg'} className={'mt-[-4px]'}>
							<Input
								value={input.affiliateInterestMin}
								onInputChange={(v) => onInputChange(v, 'affiliateInterestMin')}
								size={'small'}
								height={'40px'}
								label={'aff-interest-min'}
								currency={input.currency?.id}
								number
							/>
						</Background>

						<Background bg={'mainBg'} className={'mt-[-4px]'}>
							<Input
								value={input.affiliateInterestMax}
								onInputChange={(v) => onInputChange(v, 'affiliateInterestMax')}
								size={'small'}
								height={'40px'}
								label={'aff-interest-max'}
								currency={input.currency?.id}
								number
							/>
						</Background>
					</div>
				</div>

				<div className={'flex flex-col gap-3 px-5 mt-5 mb-3'}>
					<div
						className={`bg-active text-slate-800 font-semibold rounded-md h-[40px]
                w-full cursor-pointer flex items-center justify-center gap-2
                    `}
						onClick={onEditPlan}
					>
						{eidtPlanLoading ? <ClipLoader size={24} /> : null}
						<Text tid={`update-plan`} />
					</div>

					<div
						className={`border-red-500 border-opacity-20 border-[1px] cursor-pointer
                    font-semibold rounded-md h-[40px] w-full flex items-center justify-center
                    `}
						onClick={onClose}
					>
						<Text tid={'cancel'} />
					</div>
				</div>
			</div>
		</ModalLayout>
	)
}

export default EditUserStakeModal
