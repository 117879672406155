import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import ListLayout from '../../../components/layout/main/ListLayout'
import StakingCard from '../../../components/main/setting/staking/StakingCard'
import {
	useDeleteUserStake,
	useGetStakingPlans,
	useGetUserStake,
} from '../../../core/services/react-query/staking'
import EditUserStakeModal from '../../../components/main/reports/staking/EditUserStakeModal'
import BasicModal from '../../../components/modals/BasicModal'
import ModalLayout from '../../../components/layout/main/ModalLayout'

const StakingDetails = () => {
	const params = useParams()

	const { data: plans } = useGetStakingPlans()
	const { data: userStake } = useGetUserStake(params?.id)

	const userPlan = useMemo(() => {
		if (!!userStake && !!plans) {
			return plans.find((x) => x.id === userStake.planId)
		}
	}, [plans, userStake])

	const initEditModal = { show: false, data: null }
	const [editModal, setEditModal] = useState(initEditModal)
	const onEditStake = (item, idx) => {
		setEditModal({ show: true, data: item })
	}
	const closeEditModal = () => setEditModal(initEditModal)

	const { mutate: deleteUserStake, isLoading: deleteStakeLoading } = useDeleteUserStake()
	const [deleteModal, setDeleteModal] = useState(false)
	const closeDeleteModal = () => setDeleteModal(false)
	const openDeleteModal = () => setDeleteModal(true)
	const onRemoveStake = () => {
		deleteUserStake(userStake?._id)
	}

	return (
		<ListLayout>
			<div className={'flex items-center justify-center dark:text-gray-300'}>
				<StakingCard
					item={userPlan}
					idx={1}
					isDetail={true}
					user={userStake}
					onEdit={onEditStake}
					onRemove={openDeleteModal}
				/>
			</div>

			<EditUserStakeModal modal={editModal} userStake={userStake} onClose={closeEditModal} />
			<ModalLayout width={'580px'} open={deleteModal} onClose={closeDeleteModal}>
				<BasicModal
					onClose={closeDeleteModal}
					onSubmit={onRemoveStake}
					loading={deleteStakeLoading}
				/>
			</ModalLayout>
		</ListLayout>
	)
}

export default StakingDetails
