import { stakingReportAttributes } from '../../../../core/constants/headers'
import { Column, Relative, Row } from '../../../../styles/CommonStyles'
import TableLayout from '../../../layout/main/TableLayout'
import useGetTableIndex from '../../../../core/hooks/layout/useGetTableIndex'
import { Link, useNavigate } from 'react-router-dom'
import { formatNumber, getNames } from '../../../../core/utils/common'
import CoinRow from '../../../common/common/CoinRow'
import DateTime from '../../../common/utils/DateTime'
import Operation from '../../../common/utils/Operation'

const StakingReportsList = ({ data }) => {
  const { getTableIndex } = useGetTableIndex()
  const { cs, headers } = stakingReportAttributes

  const navigate = useNavigate()
  const onDetailsClicked = (item) => {
    navigate(`/reports/staking/${item._id}`)
  }

  return (
    <TableLayout data={data} cs={cs} headers={headers}>
      {data?.data?.data?.map((item, idx) => {
        const { FULLNAME } = getNames(item.user.firstName, item.user.lastName)

        return (
          <Relative key={item._id}>
            <Row index={idx} cs={cs}>
              <Column>{getTableIndex(idx)}</Column>
              <Column>
                <Link to={`/users/${item.userId}`}>
                  <span className={'underline'}>{FULLNAME}</span>
                </Link>
              </Column>
              <Column>
                <CoinRow coin={{ id: item.currency, name: item.currency, fa: item.currency }} />
              </Column>

              <Column>
                <span>{formatNumber(item.amount, { type: item.currency })}</span>
              </Column>

              <Column>
                <Link to={`/reports/staking/${item._id}`}>
                  <div
                    className={`
                                dark:bg-slate-800 bg-slate-200 rounded py-2 px-3
                                flex items-center gap-2 cursor-pointer
                                `}
                  >
                    <span className={'mt-1 max-w-[95%] truncate'}>{item.planId}</span>
                  </div>
                </Link>
              </Column>
              <Column center>
                <DateTime dt={item.createdAt} />
              </Column>

              <Column></Column>
            </Row>

            <Column operation>
              <Operation onDetailsClicked={() => onDetailsClicked(item)} hasDetails />
            </Column>
          </Relative>
        )
      })}
    </TableLayout>
  )
}

export default StakingReportsList
