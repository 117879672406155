import { ClipLoader } from 'react-spinners'
import { useMainContext } from '../../../../core/contexts/main'
import { formatDate, formatNumber, getNames } from '../../../../core/utils/common'
import { getMainTheme } from '../../../../core/utils/theme'

const { SOCKET_URL } = require('../../../../core/constants/urls')
const { default: Text } = require('../../../../core/utils/Text')
const { default: BoundBox } = require('../available-coins/details/BoundBox')

const StakingCard = ({
	item,
	idx,
	onEdit,
	onRemove,
	isDetail = false,
	onChangeStatus,
	changeStatusLoading,
	user = {},
}) => {
	const getRandomBg = (idx) => {
		const bgs = [
			'bg-green-500',
			'bg-blue-500',
			'bg-purple-500',
			'bg-orange-500',
			'bg-red-500',
			'bg-cyan-500',
			'bg-yellow-500',
		]

		return bgs[idx % 7]
	}

	const { lang } = useMainContext()
	const { FULLNAME } = getNames(user?.user?.firstName, user?.user?.lastName)

	return (
		<div
			className={`w-[360px] rounded-md ${
				item?.isActive ? 'opacity-100' : 'opacity-70'
			} dark:bg-cardBg bg-gray-100`}
		>
			<div
				className={`w-full h-[32px] ${getRandomBg(idx)} bg-opacity-30 rounded-md flex justify-end`}
			/>

			<div className={'flex flex-col gap-2 p-4'}>
				{item ? (
					<>
						<div className={'flex items-center justify-center gap-2'}>
							<img
								src={
									item.currency === 'irt'
										? require('../../../../assets/images/tooman.png')
										: SOCKET_URL + `assets/icon/${item.currency}.png`
								}
								width='32px'
								alt=' '
							/>
							<span className={'dark:text-white mt-2'}>{item.currency.toUpperCase()}</span>
						</div>

						<div className={'text-center'}>
							<span className={'dark:text-white text-xl'}>{item.name}</span>
						</div>

						<div className={'text-center mt-[-4px] relative overflow-hidden h-[70px]'}>
							<span className={'dark:text-gray-300 text-xs'}>{item.description}</span>
						</div>
						<div className={'bg-slate-500 bg-opacity-10 h-[1px]'} />
						<BoundBox min={item.minAmount} max={item.maxAmount} currency={item.currency} />

						<div
							className={
								'grid grid-cols-12 items-center gap-2 p-3 rounded-md dark:bg-primaryBg text-[0.7rem]'
							}
						>
							<DetailRow
								prefix={'interest-rate'}
								suffix={`${formatNumber(user?.custom?.interestRate || item.interestRate)} %`}
							/>

							<DetailRow
								prefix={'interest-min'}
								suffix={`${formatNumber(
									user?.custom?.interestMin || item.interestMin
								)} ${item.currency.toUpperCase()}`}
							/>

							<DetailRow
								prefix={'interest-max'}
								suffix={`${formatNumber(
									user?.custom?.interestMax || item.interestMax
								)} ${item.currency.toUpperCase()}`}
							/>

							<DetailRow
								prefix={'aff-interest-rate'}
								suffix={`${formatNumber(
									user?.custom?.affiliateInterestRate || item.affiliateInterestRate
								)} %`}
							/>

							<DetailRow
								prefix={'aff-interest-min'}
								suffix={`${formatNumber(
									user?.custom?.affiliateInterestMin || item.affiliateInterestMin
								)} ${item.currency.toUpperCase()}`}
							/>

							<DetailRow
								prefix={'aff-interest-max'}
								suffix={`${formatNumber(
									user?.custom?.affiliateInterestMax || item.affiliateInterestMax
								)} ${item.currency.toUpperCase()}`}
							/>
							<DetailRow prefix={'staking-period'} suffix={`${item.periodDays}`} />
						</div>
					</>
				) : (
					<div className={'h-[300px]'}>
						<div className={'flex h-full items-center justify-center'}>
							<ClipLoader size={24} color={'#ffc800'} />
						</div>
					</div>
				)}

				{isDetail ? (
					<>
						<div
							className={
								'grid grid-cols-12 items-center gap-2 p-3 rounded-md dark:bg-primaryBg text-[0.7rem]'
							}
						>
							<DetailRow prefix={'username'} suffix={FULLNAME} />

							<DetailRow
								prefix={'amount'}
								suffix={formatNumber(user.amount, { type: user.currency })}
							/>
							<DetailRow prefix={'date'} suffix={`${formatDate(user.createdAt, 'date', lang)}`} />
						</div>
					</>
				) : null}

				<div
					className={`
                rounded-md h-[36px] mt-2 text-sm border-[1px] border-active border-opacity-40 
                flex items-center justify-center hover:bg-active hover:bg-opacity-70 hover:text-gray-800 transition cursor-pointer
                `}
					onClick={() => onEdit(item, idx)}
				>
					<Text tid={'edit-plan'} />
				</div>

				{!isDetail ? (
					<div
						className={`
                rounded-md h-[36px] mt-2 text-sm border-[1px] border-opacity-40 
                flex items-center justify-center gap-2 cursor-pointer
                ${item.isActive ? 'border-pink-500' : 'border-cyan-500'}
                `}
						onClick={() => onChangeStatus(item, idx)}
					>
						{changeStatusLoading ? <ClipLoader size={20} color={getMainTheme().active} /> : null}
						<Text tid={item.isActive ? 'stop-plan' : 'start-plan'} />
					</div>
				) : null}
				<div
					className={`
                rounded-md h-[36px] text-sm border-[1px] border-red-500 border-opacity-40 
                flex items-center justify-center hover:bg-red-500 hover:bg-opacity-70 hover:text-gray-800 transition cursor-pointer
                `}
					onClick={() => onRemove(item, idx)}
				>
					<Text tid={'remove-plan'} />
				</div>
			</div>
		</div>
	)
}

const DetailRow = ({ prefix, suffix }) => {
	return (
		<>
			<Text tid={prefix} className={'text-gray-400 col-span-5'} />
			<div className={'border-[1px] border-dashed border-gray-500 border-opacity-20 col-span-3'} />
			<span dir={'ltr'} className={'col-span-4'}>
				{suffix}
			</span>
		</>
	)
}

export default StakingCard
