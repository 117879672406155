import ListLayout from '../../../components/layout/main/ListLayout'
import { useGetStakingReports } from '../../../core/services/react-query/staking'
import { PageCard } from '../../../styles/CommonStyles'
import { CacheKeys, stakingReportOptions } from '../../../core/constants/filter'
import { useState } from 'react'
import FilterLayout from '../../../components/layout/filter/FilterLayout'
import { useWindowSize } from '../../../core/hooks/common/useWindowSize'
import { TABLET_SIZE } from '../../../core/constants/common'
import StakingReportsList from '../../../components/main/reports/staking/StakingReportsList'
import RStakingReportsList from '../../../components/main/reports/staking/RStakingReportsList'

const Staking = () => {
  const { width } = useWindowSize()
  const [stakings, setStakings] = useState({ loading: true, data: null })
  const Comp = width > TABLET_SIZE ? StakingReportsList : RStakingReportsList

  return (
    <ListLayout>
      <PageCard>
        <FilterLayout
          query={useGetStakingReports}
          cache={CacheKeys.STAKING_REPORTS}
          onQuerySuccess={setStakings}
          options={stakingReportOptions}
        >
          <Comp data={stakings} />
        </FilterLayout>
      </PageCard>
    </ListLayout>
  )
}

export default Staking
