import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import TransactionBalances from './TransactionBalances'
import TransactionsHistoryChart from './TransactionsHistoryChart'
import AnalysisTabs from './AnalysisTab'

const tabs = ['deposit', 'withdraw']

const DepositWithdrawAnalysis = ({ onBack }) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'deposit')
	const onTabChange = (tab) => {
		setActiveTab(tab)
		setSearchParams({ tab: tab, sub: 'deposit-withdraw' })
	}

	return (
		<div className={'flex flex-col gap-5 w-full'}>
			<AnalysisTabs onBack={onBack} tabs={tabs} activeTab={activeTab} onTabChange={onTabChange} />

			{/* Deposit/Withdraw Balances */}
			<TransactionBalances flow={activeTab} />

			<TransactionsHistoryChart flow={activeTab} />
		</div>
	)
}

export default DepositWithdrawAnalysis
