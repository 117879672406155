import { useEffect, useState } from 'react'
import { useAddStakingPlan, useGetStakingPlans } from '../../../core/services/react-query/staking'
import ListLayout from '../../../components/layout/main/ListLayout'
import Text from '../../../core/utils/Text'
import { FaPlus } from 'react-icons/fa'
import StakingCard from '../../../components/main/setting/staking/StakingCard'
import StakingModal from '../../../components/main/setting/staking/StakingModal'
import { useSearchParams } from 'react-router-dom'
import { deepCopy } from '../../../core/utils/common'
import BasicModal from '../../../components/modals/BasicModal'
import ModalLayout from '../../../components/layout/main/ModalLayout'

const Staking = () => {
	const [searchParams] = useSearchParams()
	const { data: stakingPlans } = useGetStakingPlans()

	const [cachedPlans, setCachedPlans] = useState([])
	const [onBoard, setOnBoard] = useState([])

	useEffect(() => {
		if (stakingPlans?.length) setCachedPlans(stakingPlans)
	}, [stakingPlans])

	const initialAdd = { data: null, open: false, idx: -1 }
	const [addModal, setAddModal] = useState(initialAdd)
	const openAddModal = (data, idx) => setAddModal({ data, open: true, idx })
	const closeAddModal = () => setAddModal(initialAdd)

	useEffect(() => {
		if (stakingPlans?.length) {
			const id = searchParams.get('id')
			if (!!id) setOnBoard(stakingPlans.filter((s) => s.id === id))
			else setOnBoard(stakingPlans)
		}
	}, [searchParams, stakingPlans])

	const removeInitial = { show: false, data: null, idx: -1 }
	const [removeModal, setRemoveModal] = useState(removeInitial)
	const closeRemoveModal = () => setRemoveModal(removeInitial)
	const openRemoveModal = (data, idx) => setRemoveModal({ data, idx, show: true })

	const { mutate: changeStatus, isLoading: changeStatusLoading } =
		useAddStakingPlan(closeRemoveModal)

	const onRemove = () => {
		const newPlans = deepCopy(cachedPlans)
		newPlans.splice(removeModal.idx, 1)
		changeStatus(newPlans)
	}

	const onChangeStatus = (item, idx) => {
		const newPlans = deepCopy(cachedPlans)
		newPlans[idx].isActive = !item.isActive
		changeStatus(newPlans)
	}

	return (
		<ListLayout>
			<div className={'w-full flex flex-col gap-5 dark:text-gray-300'}>
				<div className={'rounded-md w-full h-[58px] dark:bg-cardBg bg-gray-100'}>
					<div className={'flex items-center h-full px-8 justify-between gap-2'}>
						<Text tid={'staking-plans'} />
						<div
							className={`rounded-md bg-active cursor-pointer text-gray-700 py-2 px-3 flex items-center gap-2
                            `}
							onClick={openAddModal}
						>
							<FaPlus size={16} />
							<Text tid={'add-plan'} className={'text-sm'} />
						</div>
					</div>
				</div>
				<div className={'flex flex-wrap gap-3'}>
					{onBoard?.map((item, idx) => {
						return (
							<div key={item.id}>
								<StakingCard
									item={item}
									idx={idx}
									onEdit={openAddModal}
									onRemove={openRemoveModal}
									onChangeStatus={onChangeStatus}
									changeStatusLoading={changeStatusLoading}
								/>
							</div>
						)
					})}
				</div>
			</div>

			<ModalLayout onClose={closeRemoveModal} open={removeModal.show} width={'580px'}>
				<BasicModal loading={changeStatusLoading} onSubmit={onRemove} onClose={closeRemoveModal} />
			</ModalLayout>

			<StakingModal details={addModal} onClose={closeAddModal} cache={cachedPlans} />
		</ListLayout>
	)
}

export default Staking
