import { useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import OrderOtcWages from './OrderOtcWages'
import AnalysisTabs from './AnalysisTab'
import {
	useGetAnalysisWages,
	useGetAnalysisWagesCsv,
} from '../../../core/services/react-query/analysis'
import { useAnalysisDateParams } from './analysisDateParams'

const tabs = ['order', 'otc', 'deposit', 'withdraw', 'internalTransfer']

const OrderOtcAnalysis = ({ onBack }) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'order')
	const onTabChange = (tab) => {
		setActiveTab(tab)
		setSearchParams({ tab: tab, sub: 'order-otc' })
	}

	const { params, date, setDate, customDates, setCustomDates, dates } = useAnalysisDateParams()
	const { data: wages, isLoading: wagesLoading } = useGetAnalysisWages({
		params,
		search: `?search=cause.label&query=${activeTab}&skipRobot=true`,
	})

	const { refetch: downloadWages, isLoading: downloadingWages } = useGetAnalysisWagesCsv({
		params,
		search: `?search=cause.label&query=${activeTab}&skipRobot=true&export=csv`,
	})

	const onDownloadCsv = () => {
		if (downloadingWages) return
		downloadWages()
	}

	return (
		<div className={'flex flex-col gap-5 w-full'}>
			<AnalysisTabs
				onBack={onBack}
				tabs={tabs}
				activeTab={activeTab}
				onTabChange={onTabChange}
				hasDownload={true}
				onDownload={onDownloadCsv}
				downloadLoading={downloadingWages}
			/>
			<OrderOtcWages
				flow={activeTab}
				dates={dates}
				customDates={customDates}
				setCustomDates={setCustomDates}
				date={date}
				setDate={setDate}
				wages={wages}
				wagesLoading={wagesLoading}
			/>
		</div>
	)
}

export default OrderOtcAnalysis
