export const CacheKeys = {
  ORDER: 'order',
  USER: 'user',
  TRADE: 'trade',
  OTC: 'otc',
  COIN_TRANSACTIONS: 'coin-transactions',
  BANK_TRANSACTIONS: 'bank-transactions',
  COIN_TRACE: 'coin-trace',
  AFFILIATE_LIST: 'affiliate-list',
  AFFILIATE_TRACE: 'affiliate-trace',
  TOKENS: 'tokens',
  ROLES: 'roles',
  FILES: 'files',
  USER_ORDERS: 'user-orders',
  USER_TRADES: 'user-trades',
  USER_OTC: 'user-otc',
  USER_COIN_TRANSACTIONS: 'user-coin-transactions',
  USER_BANK_TRANSACTIONS: 'user-bank-transactions',
  MULTI_SIGNATURE: 'multi-signature',
  BANK_ACCOUNTS: 'bank-accounts',
  ADDRESSES: 'addresses',
  FULLNODE: 'fullnode',
  USER_TRACE: 'user-trace',
  THIRD_ORDER_HISTORY: 'third-order-history',
  THIRD_WITHDRAW_HISTORY: 'third-withdraw-history',
  DEPOSIT_WEB_HOOKS: 'deposit-webhooks',
  DEPOSIT_BANK_ACCOUNTS: 'deposit-bank-accounts',
  TRANSFERS: 'transfers',
  STAKING_REPORTS: 'staking-reports',
}

export const filtersIntitialState = () => {
  const keys = Object.keys(CacheKeys)
  const initial = {}
  keys.forEach((key) => {
    const value = CacheKeys[key]
    initial[value] = {}
  })
  return initial
}

export const paginationInitialState = () => {
  const keys = Object.keys(CacheKeys)
  const initial = {}
  keys.forEach((key) => {
    const value = CacheKeys[key]
    initial[value] = { page: 1, limit: 10 }
  })
  return initial
}

const verifyOptions = ['verified', 'not-verified']
const tradeTypeOptions = ['limit', 'market', 'stop', 'oco']
const transactionTypeOptions = ['buy', 'sell']
const transactionFlowOptions = ['deposit', 'withdraw']
const statusOptions = ['success', 'error', 'pending']
const actionOptions = ['maker', 'taker', 'buy', 'sell']
const causeOptions = ['deposit', 'withdraw', 'order', 'otc', 'admin']
const tokenStatusOptions = ['is-active', 'not-active']
const multiTypeOptions = ['INCREASE', 'DECREASE']
const multiStatusOptions = ['pending', 'done', 'rejected']
const fileTypeOptions = ['user', 'banking', 'coining', 'trade', 'order']
const traceTypeOptions = ['INCREASE', 'DECREASE', 'BLOCKED']
const cumulativeStatusOptions = ['init', 'pending', 'success', 'error']

export const userFilterOptions = [
  { name: '_id', search: '_id' },
  { name: 'name', search: 'name' },
  { name: 'date', search: 'date' },
  { name: 'idNo', search: 'idNo' },
  { name: 'mobile', search: 'mobile' },
  { name: 'email', search: 'email' },
  { name: 'verifyAt', search: 'verifyAt', options: verifyOptions },
]

export const orderFilterOptions = [
  { name: '_id', search: '_id' },
  { name: 'coin', search: 'coin' },
  { name: 'pair', search: 'pair' },
  { name: 'date', search: 'date' },
  { name: 'trade-type', search: 'submit', options: tradeTypeOptions },
  { name: 'transaction-type', search: 'type', options: transactionTypeOptions },
]

export const tradeFilterOptions = [
  { name: '_id', search: '_id' },
  { name: 'coin', search: 'order.coin' },
  { name: 'pair', search: 'order.pair' },
  { name: 'date', search: 'date' },
  { name: 'trade-type', search: 'order.submit', options: tradeTypeOptions },
  { name: 'transaction-type', search: 'order.type', options: transactionTypeOptions },
]

export const userTraceFilterOptions = [
  { name: '_id', search: '_id' },
  { name: 'coin', search: 'currency' },
  { name: 'date', search: 'date' },
  { name: 'cause', search: 'cause.label', options: causeOptions },
  { name: 'action', search: 'cause.action', options: actionOptions },
  { name: 'trace-type', search: 'type', options: traceTypeOptions },
]

export const otcTradeFilterOptions = [
  { name: '_id', search: '_id' },
  { name: 'coin', search: 'coin' },
  { name: 'date', search: 'date' },
  { name: 'transaction-type', search: 'type', options: transactionTypeOptions },
]

export const coinTransactionOptions = [
  { name: '_id', search: '_id' },
  { name: 'coin', search: 'coin' },
  { name: 'transaction-flow', search: 'flow', options: transactionFlowOptions },
  { name: 'date', search: 'date' },
  { name: 'transaction-status', search: 'status', options: statusOptions },
]

export const stakingReportOptions = [{ name: '_id', search: 'id' }]

export const bankTransactionOptions = [
  { name: '_id', search: '_id' },
  { name: 'transaction-flow', search: 'flow', options: transactionFlowOptions },
  { name: 'date', search: 'date' },
  { name: 'transaction-status', search: 'status', options: statusOptions },
]

export const transferOptions = [
  { name: '_id', search: '_id' },
  { name: 'coin', search: 'currency' },
  { name: 'fromId', search: 'fromId' },
  { name: 'toId', search: 'toId' },
  { name: 'date', search: 'date' },
]

export const depositWebhookOptions = [{ name: '_id', search: '_id' }]

export const depositBankAccountOptions = [{ name: '_id', search: '_id' }]

export const coinTraceFilterOptions = [
  { name: '_id', search: '_id' },
  { name: 'coin', search: 'currency' },
  { name: 'date', search: 'date' },
  { name: 'cause', search: 'cause.label', options: causeOptions },
  { name: 'action', search: 'cause.action', options: actionOptions },
  { name: 'trace-type', search: 'type', options: traceTypeOptions },
]

export const affiliateTraceFilterOptions = [
  { name: '_id', search: '_id' },
  { name: 'coin', search: 'currency' },
  { name: 'date', search: 'date' },
  { name: 'cause', search: 'cause.label', options: causeOptions },
]

export const affiliateListFilterOptions = [
  { name: '_id', search: '_id' },
  { name: 'date', search: 'date' },
  { name: 'affiliate-code', search: 'code' },
]

export const tokenFilterOptions = [
  { name: 'token', search: 'symbol' },
  { name: 'smart-contract', search: 'contract' },
  { name: 'blockchain', search: 'name' },
  { name: 'network', search: 'network' },
  { name: 'token-status', search: 'isActive', options: tokenStatusOptions },
]

export const roleFilterOptions = [
  { name: 'role-name', search: 'name' },
  { name: 'date', search: 'date' },
]

export const fileFilterOptions = [
  { name: 'file-name', search: 'name' },
  { name: 'date', search: 'date' },
  { name: 'file-type', search: 'type', options: fileTypeOptions },
  { name: 'file-status', search: 'status', options: statusOptions },
]

export const balanceMultiSignatureFilterOptions = [
  { name: '_id', search: '_id' },
  { name: 'coin', search: 'params.currency' },
  { name: 'multi-type', search: 'params.action', options: multiTypeOptions },
  { name: 'multi-status', search: 'status', options: multiStatusOptions },
  { name: 'date', search: 'date' },
]

export const bankAccountFilterOptions = [
  { name: 'name', search: 'name' },
  { name: 'verifyAt', search: 'verifyAt', options: verifyOptions },
]

export const addressFilterOptions = [
  // {name: 'name', search: 'name'},
  { name: 'coin', search: 'coin' },
  { name: 'address', search: 'address' },
  { name: 'date', search: 'date' },
]

export const fullnodeFilterOptions = [
  { name: 'coin', search: 'coin' },
  { name: 'date', search: 'date' },
  { name: 'from-address', search: 'fromAddress' },
  { name: 'to-address', search: 'toAddress' },
  { name: 'txHash', search: 'txHash' },
  { name: 'status', search: 'cumulative.status', options: cumulativeStatusOptions },
]

export const currentOrderSortOptions = [
  { title: 'traded-amount', sort: 'tradedAmount' },
  { title: 'unitPrice', sort: 'priceUnit' },
  { title: 'price', sort: 'price' },
  { title: 'date', sort: 'createdAt' },
]

export const tradeSortOptions = [
  { title: 'amount', sort: 'amount' },
  { title: 'unitPrice', sort: 'priceUnit' },
  { title: 'price', sort: 'price' },
  { title: 'date', sort: 'createdAt' },
]

export const otcSortOptions = [
  { title: 'amount', sort: 'amount' },
  { title: 'wage', sort: 'wage' },
  { title: 'unitPrice', sort: 'priceUnit' },
  { title: 'price', sort: 'price' },
  { title: 'date', sort: 'createdAt' },
]

export const userSortOptions = [{ title: 'balance', sort: 'balance' }]

export const cartableSortOptions = [{ title: 'date', sort: 'createdAt' }]

export const fileSortOptions = [{ title: 'date', sort: 'createdAt' }]

export const coinTransactionSortOptions = [
  { title: 'amount', sort: 'amount' },
  { title: 'date', sort: 'createdAt' },
]

export const bankTransactionSortOptions = [
  { title: 'amount', sort: 'amount' },
  { title: 'date', sort: 'createdAt' },
]

export const traceSortOptions = [
  { title: 'volume', sort: 'volume' },
  { title: 'wage', sort: 'wage' },
  { title: 'date', sort: 'createdAt' },
]

export const affiliateListSortOptions = [
  { title: 'use-count', sort: 'used' },
  { title: 'max-use', sort: 'maxUse' },
  { title: 'date', sort: 'createdAt' },
]

export const addressSortOptions = [{ title: 'date', sort: 'createdAt' }]

export const fullnodeSortOptions = [{ title: 'date', sort: 'createdAt' }]

export const roleSortOptions = [
  { title: 'name', sort: 'name' },
  { title: 'date', sort: 'createdAt' },
]

export const tokenSortOptions = [
  { title: 'blockchain', sort: 'name' },
  { title: 'decimal', sort: 'decimal' },
]
