import { UserDetailListWrapper } from '../../../../styles/main/user'
import { CacheKeys, stakingReportOptions } from '../../../../core/constants/filter'
import FilterLayout from '../../../layout/filter/FilterLayout'
import { useState } from 'react'
import { useWindowSize } from '../../../../core/hooks/common/useWindowSize'
import { TABLET_SIZE } from '../../../../core/constants/common'
import { useGetStakingReports } from '../../../../core/services/react-query/staking'
import { useParams } from 'react-router-dom'
import StakingReportsList from '../../reports/staking/StakingReportsList'
import RStakingReportsList from '../../reports/staking/RStakingReportsList'

const UserStaking = () => {
  const params = useParams()
  const { width } = useWindowSize()
  const [stakings, setStakings] = useState({ loading: true, data: null })

  const Comp = width > TABLET_SIZE ? StakingReportsList : RStakingReportsList

  return (
    <UserDetailListWrapper>
      <FilterLayout
        query={useGetStakingReports}
        cache={CacheKeys.STAKING_REPORTS}
        onQuerySuccess={setStakings}
        options={stakingReportOptions}
        extra={{ userId: params.id }}
      >
        <Comp data={stakings} />
      </FilterLayout>
    </UserDetailListWrapper>
  )
}

export default UserStaking
