import { useMemo, useState } from "react"


export const useAnalysisDateParams = () => {
    const dates = ['today', 'this-week', 'this-month', 'this-year', 'custom']
    const [date, setDate] = useState('today')
	const [customDates, setCustomDates] = useState([])

	const params = useMemo(() => {
		const from = new Date()
		from.setUTCHours(0, 0, 0, 0)
		const to = new Date()
		switch (date) {
			case 'today':
				return { dateFrom: from.toISOString(), dateTo: to.toISOString() }
			case 'this-week':
				const daysAfterWeek = to.getDay() + 1
				from.setDate(from.getDate() - daysAfterWeek)
				return { dateFrom: from.toISOString(), dateTo: to.toISOString() }
			case 'this-month':
				from.setDate(1)
				return { dateFrom: from.toISOString(), dateTo: to.toISOString() }
			case 'this-year':
				from.setMonth(0)
				from.setDate(1)
				return { dateFrom: from.toISOString(), dateTo: to.toISOString() }
			default:
				break
		}

		if (customDates?.length > 1) {
			return {
				dateFrom: new Date(customDates[0]).toISOString(),
				dateTo: new Date(customDates[1]).toISOString(),
			}
		}
	}, [date, customDates])

    return {
        params,
        setDate,
        setCustomDates,
        date,
        customDates,
        dates
    }
}