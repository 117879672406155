import { FaChevronRight, FaDownload } from 'react-icons/fa'
import { useMainContext } from '../../../core/contexts/main'
import Text from '../../../core/utils/Text'
import { ClipLoader } from 'react-spinners'
import { getMainTheme } from '../../../core/utils/theme'

const AnalysisTabs = ({
	onBack,
	tabs = [],
	activeTab,
	onTabChange,
	hasDownload = false,
	onDownload = () => {},
	downloadLoading = false,
}) => {
	const { lang } = useMainContext()

	return (
		<div
			className={`
        rounded-md bg-gray-100 dark:bg-mainBg py-3 px-3 lg:px-5 w-full relative
        border-[1px] flex-wrap border-slate-500 border-opacity-30 flex items-center gap-2 lg:gap-3
        `}
		>
			<div
				className={
					'p-2 dark:text-slate-200 cursor-pointer hover:bg-slate-500 hover:bg-opacity-20 rounded'
				}
				onClick={onBack}
			>
				<FaChevronRight className={`${lang === 'fa' ? 'rotate-0' : 'rotate-180'}`} />
			</div>
			{tabs.map((item) => {
				const active = activeTab === item
				return (
					<div
						key={item}
						className={`${active ? 'bg-active text-slate-800 dark:text-slate-800' : ''}
                    rounded border-[1px] border-slate-500 border-opacity-30
                    lg:px-12 py-2 px-5 font-semibold cursor-pointer dark:text-gray-200
                    text-xs lg:text-sm transition`}
						onClick={() => onTabChange(item)}
					>
						<Text tid={item} />
					</div>
				)
			})}

			{hasDownload ? (
				<div className={'lg:absolute left-2 top-0 w-max h-full flex items-center justify-center '}>
					<div
						onClick={onDownload}
						className={
							'p-2 rounded-md cursor-pointer dark:text-gray-200 dark:bg-slate-800 bg-slate-200 text-xs lg:text-sm flex items-center gap-1'
						}
					>
						{downloadLoading ? (
							<ClipLoader size={20} color={getMainTheme().active} />
						) : (
							<FaDownload size={15} />
						)}
						<Text tid={'download-report'} />
					</div>
				</div>
			) : null}
		</div>
	)
}

export default AnalysisTabs
