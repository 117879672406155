import ModalLayout from "../../../layout/main/ModalLayout"
import CoinSelect from "../../../common/input/CoinSelect"
import { useEffect, useState } from "react"
import { useAvailableCoins } from "../../../../core/services/react-query/setting"
import { deepCopy, formatNumber, stringToNumber } from "../../../../core/utils/common"
import Text from "../../../../core/utils/Text"
import Input from "../../../common/input/Input"
import { Background, Padding } from "../../../../styles/CommonStyles"
import { TextArea } from "../../../../styles/main/user";
import { useWindowSize } from "../../../../core/hooks/common/useWindowSize"
import { TABLET_SIZE } from "../../../../core/constants/common"
import { useAddStakingPlan } from "../../../../core/services/react-query/staking"
import { ClipLoader } from "react-spinners"

const initialInput = {
    id: '',
    currency: null,
    name: '',
    description: '',
    minAmount: '',
    maxAmount: '',
    interestRate: '',
    interestMin: '',
    interestMax: '',
    affiliateInterestRate: '',
    affiliateInterestMin: '',
    affiliateInterestMax: '',
    periodDays: '',
    settlements: '',
    isActive: true
}
const tooman = { id: 'irt', fa: 'تومان', name: 'Tooman' }
function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
}

const StakingModal = ({ details, onClose, cache }) => {

    const { width, height } = useWindowSize()
    const { data: availableCoins } = useAvailableCoins()

    const isUpdate = details.idx > -1

    const [input, setInput] = useState(initialInput)
    useEffect(() => {
        if (details.data && availableCoins?.length && details.idx > -1) {
            const newDetails = deepCopy(details.data)
            const currId = details.data.currency
            let curr
            try {

                if (currId === 'irt') curr = tooman
                else curr = availableCoins?.find(x => x.id === currId)
            } catch (err) { }
            if (!!curr) {
                newDetails.currency = curr
            }
            setInput(newDetails)
        }
    }, [details, availableCoins])

    const onInputChange = (v, t) => {
        setInput(s => ({ ...s, [t]: v }))
    }

    const { mutate: addPlan, isLoading: addPlanLoading } = useAddStakingPlan(onClose)

    const onAddPlan = () => {
        if (addPlanLoading) return

        const formatedInput = {
            name: input.name,
            description: input.description,
            currency: input.currency?.id,
            minAmount: stringToNumber(input.minAmount),
            maxAmount: stringToNumber(input.maxAmount),
            interestRate: stringToNumber(input.interestRate),
            interestMin: stringToNumber(input.interestMin),
            interestMax: stringToNumber(input.interestMax),
            affiliateInterestRate: stringToNumber(input.affiliateInterestRate),
            affiliateInterestMin: stringToNumber(input.affiliateInterestMin),
            affiliateInterestMax: stringToNumber(input.affiliateInterestMax),
            settlements: stringToNumber(input.settlements),
            periodDays: stringToNumber(input.settlements) * 30,
            isActive: true
        }

        if (isUpdate) {
            const newPlans = deepCopy(cache)
            newPlans[details.idx] = {
                ...formatedInput,
                id: details.data.id
            }
            addPlan(newPlans)
        } else {
            const randomId = uuidv4()
            addPlan([
                ...cache,
                {
                    id: randomId,
                    ...formatedInput
                }
            ])
        }

    }

    return (
        <ModalLayout
            open={details.open}
            width={'500px'}
            padding={'0'}
            isStatic={width > TABLET_SIZE}
        >
            <div className={`relative dark:text-gray-300 text-sm`}>

                <div className={`overflow-y-auto overflow-x-hidden`} style={{ maxHeight: `${height * 80 / 100}px` }}>

                    <div className={'px-3 py-3 w-full rounded-t-md dark:bg-cardBg'}>
                        <Text tid={`${isUpdate ? 'update' : 'add'}-plan`} />
                    </div>

                    <div className={'w-full flex flex-col gap-2 p-3'}>
                        <div className={'flex flex-col'}>
                            <Text tid={'name'} className={'text-sm mx-1'} />
                            <Background bg={'mainBg'} className={'mt-[-4px]'}>
                                <Input
                                    value={input.name}
                                    onInputChange={v => onInputChange(v, 'name')}
                                    size={'small'}
                                    height={'40px'}
                                />
                            </Background>
                        </div>

                        <div className={'flex flex-col mt-[-6px]'}>
                            <Text tid={'description'} className={'text-sm mx-1'} />
                            <Padding padding={'4px'}>
                                <TextArea
                                    minHeight={'64px'}
                                    className={'rounded-md'}
                                    value={input.description}
                                    onChange={e => onInputChange(e?.target?.value, 'description')}
                                />
                            </Padding>
                        </div>

                    </div>

                    <div className={'px-3 py-3 w-full text-xs flex items-center justify-between dark:bg-cardBg'}>
                        <Text tid={'currency-info'} />
                    </div>

                    <div className={'grid grid-cols-1 lg:grid-cols-2 gap-3 p-3'}>
                        <div className={'flex flex-col gap-1 lg:col-span-2 px-1'}>
                            <Text tid={'coin'} className={'mx-1'} />
                            <CoinSelect
                                value={input.currency}
                                onChange={v => onInputChange(v, 'currency')}
                                bg={'mainBg'}
                                width={'100%'}
                                hasIrt
                            />
                        </div>

                        <Background bg={'mainBg'} className={'lg:mt-[-4px]'}>
                            <Input
                                value={input.minAmount}
                                onInputChange={v => onInputChange(v, 'minAmount')}
                                size={'small'}
                                height={'40px'}
                                label={'min-amount'}
                                currency={input.currency?.id}
                                number
                            />
                        </Background>

                        <Background bg={'mainBg'} className={'mt-[-12px] lg:mt-[-4px]'}>
                            <Input
                                value={input.maxAmount}
                                onInputChange={v => onInputChange(v, 'maxAmount')}
                                size={'small'}
                                height={'40px'}
                                label={'max-amount'}
                                currency={input.currency?.id}
                                number
                            />
                        </Background>


                    </div>

                    <div className={'px-3 py-3 w-full text-xs flex items-center justify-between dark:bg-cardBg'}>
                        <Text tid={'interest-info'} />
                    </div>
                    <div className={'grid grid-cols-1 lg:grid-cols-2 gap-x-3 p-3'}>
                        <Background bg={'mainBg'} className={'mt-[-4px]'}>
                            <Input
                                value={input.interestRate}
                                onInputChange={v => onInputChange(v, 'interestRate')}
                                size={'small'}
                                height={'40px'}
                                label={'interest-rate-pc'}
                                code
                            />
                        </Background>

                        <Background bg={'mainBg'} className={'mt-[-4px]'}>
                            <Input
                                value={input.affiliateInterestRate}
                                onInputChange={v => onInputChange(v, 'affiliateInterestRate')}
                                size={'small'}
                                height={'40px'}
                                label={'aff-interest-rate-pc'}
                                code
                            />
                        </Background>

                        <Background bg={'mainBg'} className={'mt-[-4px]'}>
                            <Input
                                value={input.interestMin}
                                onInputChange={v => onInputChange(v, 'interestMin')}
                                size={'small'}
                                height={'40px'}
                                label={'interest-min'}
                                currency={input.currency?.id}
                                number
                            />
                        </Background>

                        <Background bg={'mainBg'} className={'mt-[-4px]'}>
                            <Input
                                value={input.interestMax}
                                onInputChange={v => onInputChange(v, 'interestMax')}
                                size={'small'}
                                height={'40px'}
                                label={'interest-max'}
                                currency={input.currency?.id}
                                number
                            />
                        </Background>


                        <Background bg={'mainBg'} className={'mt-[-4px]'}>
                            <Input
                                value={input.affiliateInterestMin}
                                onInputChange={v => onInputChange(v, 'affiliateInterestMin')}
                                size={'small'}
                                height={'40px'}
                                label={'aff-interest-min'}
                                currency={input.currency?.id}
                                number
                            />
                        </Background>

                        <Background bg={'mainBg'} className={'mt-[-4px]'}>
                            <Input
                                value={input.affiliateInterestMax}
                                onInputChange={v => onInputChange(v, 'affiliateInterestMax')}
                                size={'small'}
                                height={'40px'}
                                label={'aff-interest-max'}
                                currency={input.currency?.id}
                                number
                            />
                        </Background>
                    </div>


                    <div className={'px-3 py-3 w-full text-xs flex items-center justify-between dark:bg-cardBg'}>
                        <Text tid={'plan-period'} />
                    </div>

                    <div className={'grid grid-cols-1 lg:grid-cols-2 mt-2 px-3 gap-x-3 py-1'}>
                        <Background bg={'mainBg'} className={'mt-[-4px]'}>
                            <Input
                                value={input.settlements}
                                onInputChange={v => onInputChange(v, 'settlements')}
                                size={'small'}
                                height={'40px'}
                                label={'settlements'}
                                code
                            />
                        </Background>

                        <div className={'rounded-md h-[42px] bg-cardBg mt-1 flex items-center gap-1 px-3 text-xs'}>
                            <Text tid={'staking-period'} />
                            <span>:</span>
                            <span>{formatNumber(input.settlements * 30, { type: 'irt' })}</span>
                        </div>
                    </div>
                </div>


                <div className={'flex flex-col gap-3 px-5 mt-5 mb-3'}>
                    <div
                        className={`bg-active text-slate-800 font-semibold rounded-md h-[40px]
                        w-full cursor-pointer flex items-center justify-center gap-2
                            `}
                        onClick={onAddPlan}
                    >
                        {addPlanLoading ?
                            <ClipLoader size={24} />
                            : null}
                        <Text tid={`${isUpdate ? 'update' : 'add'}-plan`} />
                    </div>

                    <div
                        className={`border-red-500 border-opacity-20 border-[1px] cursor-pointer
                            font-semibold rounded-md h-[40px] w-full flex items-center justify-center
                            `}
                        onClick={onClose}
                    >
                        <Text tid={'cancel'} />
                    </div>
                </div>
            </div>
        </ModalLayout>
    )
}

export default StakingModal