import ListLayout from '../../components/layout/main/ListLayout'
import { PageCard } from '../../styles/CommonStyles'
import { useRef } from 'react'
import HorizontalTabbar from '../../components/common/tabs/HorizontalTabbar'
import UserSubpages from '../../components/main/users/UserSubpages'
import { UserDetailsWrapper } from '../../styles/main/user'
import { useNavigate, useParams } from 'react-router-dom'
import { useAclContext } from '../../core/contexts/acl'

const UserDetails = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { permissions } = useAclContext()

  const tabs = [
    { name: 'profile-info', ref: useRef(), perm: true },
    { name: 'user-balances', ref: useRef(), perm: permissions?.balance?.read },
    { name: 'bank-accounts', ref: useRef(), perm: true },
    { name: 'wallets', ref: useRef(), perm: true },
    { name: 'current-orders', ref: useRef(), perm: permissions?.order?.read },
    { name: 'trades', ref: useRef(), perm: permissions?.trade?.read },
    { name: 'otc-orders', ref: useRef(), perm: permissions?.otc?.read },
    { name: 'coin-transactions', ref: useRef(), perm: permissions?.coining?.read },
    { name: 'bank-transactions', ref: useRef(), perm: permissions?.banking?.read },
    { name: 'account-history', ref: useRef(), perm: true },
    { name: 'affiliate', ref: useRef(), perm: permissions?.affiliate?.read },
    { name: 'trace', ref: useRef(), perm: true },
    { name: 'staking', ref: useRef(), perm: true },
    { name: 'setting', ref: useRef(), perm: true },
  ]
  // const [activeTab, setActiveTab] = useState(location.state ? 10 : 0) // TODO: ?!
  const activeTab = tabs.findIndex((item) => item.name === params.tab)

  const onTabChange = (idx) => {
    const tab = tabs[idx]
    navigate(`/users/${params.id}/${tab.name}`)
  }

  return (
    <ListLayout>
      <PageCard padding={'0'} borderRadius={'4px'}>
        <UserDetailsWrapper>
          <HorizontalTabbar
            tabs={tabs}
            activeTab={activeTab > -1 ? activeTab : 0}
            onTabChange={onTabChange}
            controlRef={useRef()}
          />
          <UserSubpages activeTab={activeTab > -1 ? activeTab : 0} />
        </UserDetailsWrapper>
      </PageCard>
    </ListLayout>
  )
}

export default UserDetails
