import { useEffect, useState } from 'react'
import ListLayout from '../../components/layout/main/ListLayout'
import { useSearchParams } from 'react-router-dom'
import OrderOtcAnalysis from '../../components/main/analysis/OrderOtcAnalysis'
import DepositWithdrawAnalysis from '../../components/main/analysis/DepositWithdrawAnalysis'
import { ReactComponent as DWAnalysis } from '../../assets/illustrations/deposit-withdraw-analysis.svg'
import { ReactComponent as OOAnalysis } from '../../assets/illustrations/order-otc-analysis.svg'
import { ReactComponent as UBAnalysis } from '../../assets/illustrations/user-balances-analysis.svg'
import Text from '../../core/utils/Text'
import AllBalances from '../../components/main/analysis/AllBalances'

const Analysis = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const subs = [
		{ id: 'user-balances', Img: UBAnalysis },
		{ id: 'deposit-withdraw', Img: DWAnalysis },
		{ id: 'order-otc', Img: OOAnalysis },
	]
	const [subpage, setSubpage] = useState()
	const onSubClick = (sub) => {
		setSubpage(sub)
		setSearchParams({ sub: sub })
	}

	useEffect(() => {
		setSubpage(searchParams.get('sub'))
	}, [searchParams])

	const onBack = () => {
		setSubpage('')
		setSearchParams({})
	}

	return (
		<ListLayout>
			{!subpage ? (
				<div className={'flex items-center justify-center flex-wrap pb-10 gap-8'}>
					{subs.map((item) => {
						const { Img, id } = item
						return (
							<div
								key={id}
								className={`rounded dark:bg-secondaryBg bg-slate-100 p-5 hover:border-[1px] hover:scale-[1.1] 
                                    border-slate-500 border-opacity-20 transition cursor-pointer
                                    flex items-center flex-col gap-3
                                    `}
								onClick={() => onSubClick(id)}
							>
								<Img width={164} height={164} />
								<Text tid={`${id}-analysis`} className={'text-sm dark:text-slate-200'} />
							</div>
						)
					})}
				</div>
			) : null}

			{subpage === 'deposit-withdraw' ? <DepositWithdrawAnalysis onBack={onBack} /> : null}
			{subpage === 'order-otc' ? <OrderOtcAnalysis onBack={onBack} /> : null}
			{subpage === 'user-balances' ? <AllBalances onBack={onBack} /> : null}
		</ListLayout>
	)
}

export default Analysis
